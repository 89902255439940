import { useState, useCallback, useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import { useLocation } from "@reach/router";
import { SlotType } from "../../../types/contentful.types";
import {
  pathToString,
  stringToPath,
} from "../../../utilities/string-path-convertor";

export const useSlotsWithFilter = ({ slots }) => {
  const { pathname } = useLocation();
  const SLOTS_PER_PAGE = isMobile ? 15 : 20;
  const [page, setPage] = useState(1);
  const [filteredSlots, setFilteredSlots] = useState<SlotType[]>(slots); // we set slots to make webcache google work
  const [activeGameSlot, setActiveGameSlot] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [sortType, setSortType] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [count, setCount] = useState(0);

  const getSlots = useCallback(
    (currentPage) => {
      const slotNumbers = SLOTS_PER_PAGE * currentPage;
      let filtered = slots;

      // Filter by category
      if (stringToPath(pathname) !== "slot-games") {
        filtered = filtered.filter((slot) => {
          if (!slot.categories) return null;
          else if (!slot.compose__page) return null;
          return slot.categories
            .map(
              (category) =>
                category.title.toLowerCase().split(" ").join("-") + "/" ===
                pathname.replace("/slot-games/", "")
            )
            .includes(true);
        });
      }

      // Filter by search input
      filtered = filtered.filter((slot) =>
        slot.compose__page[0].title
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      );

      if (!!sortType) {
        // Sort
        filtered.sort((a, b) => {
          const valueA = a.compose__page[0][sortType].toLowerCase();
          const valueB = b.compose__page[0][sortType].toLowerCase();

          if (sortOrder === "asc") {
            return valueA.localeCompare(valueB);
          } else {
            return valueB.localeCompare(valueA);
          }
        });
      }

      setCount(filtered.length);
      setFilteredSlots(filtered.slice(0, slotNumbers));
    },
    [slots, pathname, searchInput, sortType, sortOrder]
  );

  useEffect(() => getSlots(page), [getSlots]);

  const hasFilters = useMemo(
    () =>
      !!sortType ||
      !!(pathToString(pathname.replace("/slot-games", ""))) ||
      !!sortOrder ||
      !!searchInput,
    [sortType, sortOrder, searchInput]
  );
  return {
    count,
    hasFilters,
    SLOTS_PER_PAGE,
    filteredSlots,
    searchInput,
    sortValue: `${sortType}-${sortOrder}`,
    page,
    activeGameSlot,
    getSlots,
    setPage,
    setSearchInput,
    setSortType,
    setSortOrder,
    setActiveGameSlot,
  };
};
