import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { SlotsTypesType } from "../../types/slots-types.type"
import SlotsGallery from "../slots-gallery"
import SlotCard from "../slot-card"
import GallerySection from "./gallery-scetion"

const SlotsTypes = ({
  slots,
  description,
  showDescription,
  onDownloadClick,
}: SlotsTypesType) => {

  return (
    <div className='slots-types-section'>
      {showDescription ? (
        <GallerySection
          title={description}
          bgColor='#07081d'
          infinite={true}
          containerClass='xl:pl-[10%] xl:pr-[10%] xl:pb-32 slots-cards inner-container'
          slidesToShow={3}
          responsive={[
            {
              breakpoint: 980,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
          data={slots.map((slot) => (
            <SlotCard
              key={slot.id}
              icon={slot.slotIcon}
              title={slot.compose__page?.[0].title}
              slug={slot.compose__page?.[0].slug}
              description={slot.description}
            />
          ))}
          arrowsElements={{
            left: (
              <StaticImage src='../../images/left-white-arrow.png' alt='' />
            ),
            right: (
              <StaticImage src='../../images/right-white-arrow.png' alt='' />
            ),
          }}
          arrowsPosition={{ left: "-13%", right: "-13%" }}
        />
      ) : (
        <SlotsGallery
          data={slots}
          containerClass={"pb-40"}
          showPlayButton
          onDownloadClick={onDownloadClick}
        />
      )}
    </div>
  )
}

export default SlotsTypes
