import React from "react";
import SlotGamePopup from "../../slot-game-popup";
import Slots from "./partails/Slots";
import Filters from "./partails/Filters";
import { useSlotsWithFilter } from "./useSlotsWithFilter";

import "./styles.css";

const SlotsWithFilter = ({ slotThemes, categories, slots, onDownloadClick }) => {
  const {
    hasFilters,
    SLOTS_PER_PAGE,
    filteredSlots,
    searchInput,
    page,
    activeGameSlot,
    sortValue,
    getSlots,
    setPage,
    setSearchInput,
    setSortType,
    setSortOrder,
    setActiveGameSlot,
  } = useSlotsWithFilter({slots});

  return (
    <div className="slots-with-filter-container p-10 xl:p-32 xl:pt-0 xl:mb-20 ">
      <SlotGamePopup
        slot={activeGameSlot}
        onCloseClick={() => setActiveGameSlot(null)}
        onDownloadClick={onDownloadClick}
      />

      <div className={`slots-select-container text-center text-white ${hasFilters ? 'has-filter' : ''}`}>
        <Filters
          slotThemes={slotThemes}
          categories={categories}
          searchInput={searchInput}
          sortValue={sortValue}
          setSearchInput={setSearchInput}
          setSortType={setSortType}
          setSortOrder={setSortOrder}
        />
        <Slots
          page={page}
          slotsPerPage={SLOTS_PER_PAGE}
          filteredSlots={filteredSlots}
          loadMoreCallback={() => {
            setPage(page + 1);
            getSlots(page + 1);
          }}
          setActiveGameSlot={setActiveGameSlot}
        />
      </div>
    </div>
  );
};

export default SlotsWithFilter;
