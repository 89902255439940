import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { Fragment } from "react"
import ContentfulRichText from "../../utilities/contentful-rich-text"
import Carousel from "../carousel"
import TextImageSection from "./text-image-section"

const SlotInfoTable = ({
  text,
  image,
  reels,
  jackpotType,
  jackpotTypeLinkTo,
  paylines,
  maxWager,
  rtp,
  bonusRound,
  software,
  maxPayout,
  theme,
  themeJson,
  softwareJson
}) => {
  const jackpotTypeLink =
    jackpotTypeLinkTo && jackpotTypeLinkTo.compose__page[0].slug
  const themes = themeJson ? JSON.parse(themeJson) : {};
  const softwares = softwareJson ?  JSON.parse(softwareJson) : {};
  return (
    <div className="slot-info-table-section flex flex-col xl:flex-row items-start justify-between">
      <div className="px-10 py-10 xl:px-20 flex flex-col justify-center w-full self-stretch xl:w-1/2 slot-info-table-container">
        <ContentfulRichText
          className="text-white slot-info-table-text mb-12"
          raw={text}
        />
        <table>
          <tbody>
            {reels && (
              <tr>
                <td>Reels</td>
                <td>{reels}</td>
              </tr>
            )}
            {jackpotType && (
              <tr>
                <td>Jackpot type</td>
                <td>
                  {jackpotTypeLink ? (
                    <Link to={`/${jackpotTypeLink}/`}>{jackpotType}</Link>
                  ) : (
                    jackpotType
                  )}
                </td>
              </tr>
            )}
            {paylines && (
              <tr>
                <td>Paylines</td>
                <td>{paylines}</td>
              </tr>
            )}
            {maxWager && (
              <tr>
                <td>Max. wager</td>
                <td>{maxWager}</td>
              </tr>
            )}
            {rtp && (
              <tr>
                <td>RTP %</td>
                <td>{rtp}</td>
              </tr>
            )}
            {bonusRound && (
              <tr>
                <td>Bonus type</td>
                <td>
                  {bonusRound && (
                    <Link to="/slot-games/bonus-rounds/">Bonus Round</Link>
                  )}
                </td>
              </tr>
            )}
            {(software || softwares) && (
              <tr>
                <td>Software</td>
                <td>
                  {Object.entries(softwares).length > 0
                    ? Object.entries(softwares).map(([key, value], index) => (
                        <Fragment key={key}>
                          <Link to={value as string}>{key}</Link>
                          {index !== Object.entries(softwares).length - 1 && ", "}
                        </Fragment>
                      ))
                    : software}
                </td>
              </tr>
            )}
            {maxPayout && (
              <tr>
                <td>Max. payout</td>
                <td>{maxPayout}</td>
              </tr>
            )}
            {(theme || themes) && (
              <tr>
                <td>Theme</td>
                <td>
                  {Object.entries(themes).length > 0
                    ? Object.entries(themes).map(([key, value], index) => (
                        <Fragment key={key}>
                          <Link to={value as string}>{key}</Link>
                          {index !== Object.entries(themes).length - 1 && ", "}
                        </Fragment>
                      ))
                    : theme}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="w-full xl:w-1/2 overflow-hidden">
        <GatsbyImage
          image={getImage(image.localFile.childImageSharp.gatsbyImageData)!}
          alt={image.title}
        />
      </div>
    </div>
  );
}

export default SlotInfoTable
