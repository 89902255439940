import React from "react"

const SlotInfoTabs = ({ titles, ids }: { titles: string[]; ids: string[] }) => {
  const handleOnTabTitleClick = (id) => {
    const element = document.querySelector(`#${id}`)
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" })
    }
  }

  if (!titles.length) return null

  return (
    <div className='slot-info-tabs-section pb-24 pt-24 hidden xl:block'>
      <div className='text-[24px] text-white uppercase font-bold text-center'>
        Click below to jump to the section
      </div>
      <div className='flex flex-wrap justify-center xl:px-[10%]'>
        {titles.map((title, index) => {
          return (
            <div
              key={index}
              onClick={() => handleOnTabTitleClick(ids[index])}
              className='slot-info-tab'
            >
              {title}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SlotInfoTabs
