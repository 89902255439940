import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { SlotCardType } from "../types/slot-card.type"
import ContentfulRichText from "../utilities/contentful-rich-text"

const SlotCard = ({ title, description, slug, icon }: SlotCardType) => {
  return (
    <div className='slot-card m-20 xl:m-10 pl-11 pr-11 pt-20 pb-20 flex space-y-16 flex-col justify-evenly items-center bg-[#21232f] border-[1px] border-[#41435c]'>
      <div className='relative z-10 max-w-xs'>
        <GatsbyImage
          image={getImage(icon.localFile.childImageSharp.gatsbyImageData)!}
          alt={title}
        />
      </div>
      <div className='flex flex-col space-y-6'>
        <div className='text-white font-bold text-3xl xl:text-5xl uppercase slot-card-title'>
          {title}
        </div>
        <ContentfulRichText
          raw={description}
          className='text-white text-2xl xl:text-3xl slot-card-desc'
        />
      </div>
      <div className='read-more w-full'>
        <Link
          to={`/${slug}/`}
          className='purple-button text-white text-3xl font-extrabold  text-center'
        >
          <span>Play Now</span>
        </Link>
      </div>
    </div>
  )
}

export default SlotCard
