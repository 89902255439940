import React, { useMemo, useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { CustomDropdown } from "../../../../../DS/CustomDropdown";
import {
  stringToPath,
  pathToString,
} from "../../../../../utilities/string-path-convertor";
import { StaticImage } from "gatsby-plugin-image";

const Filters = ({
  slotThemes,
  categories,
  searchInput,
  sortValue,
  setSearchInput,
  setSortType,
  setSortOrder,
}) => {
  const { pathname } = useLocation();
  const defualtView = "filter";
  const [component, setComponent] = useState(defualtView);

  const SortBy = () => (
    <CustomDropdown
      className="sort-by"
      defaultValue={sortValue}
      title="Sort By"
      items={[
        { title: "Most Popular", value: "-"},
        { title: "Title (A-Z)", value: "title-asc" },
        { title: "Title (Z-A)", value: "title-desc" },
        { title: "Old to new", value: "createdAt-asc" },
        { title: "New to old", value: "createdAt-desc" },
      ]}
      handleChange={(value) => {
        const values = value.split("-");
        setSortType(values[0]);
        setSortOrder(values[1]);
      }}
    />
  );

  const FilterSection = () => (
    <div className="flex flex-col gap-4 pt-8">
      <CustomDropdown
        className="slots-filter-select md:mt-0"
        title="Slot Types"
        items={[
          { title: "All Slots", value: "/slot-games/" },
          ...categories
            .filter((category) => category.node.title)
            .map((item) => ({
              value: `/slot-games/${stringToPath(item.node.title)}/`,
              title: item.node.title,
            })),
        ]}
        defaultValue={
          pathToString(
            pathname.replace("/slot-games", "")
          ).toLocaleLowerCase() || "all slots"
        }
        handleChange={(value) => navigate(value)}
      />
      <CustomDropdown
        className="slots-themes-filter-select md:mt-0"
        title="Slot Themes"
        items={[
          { title: "All Slots", value: "/slot-games/" },
          ...slotThemes
            .filter((category) => category.node.title)
            .map((item) => ({
              value: `/slot-games/${stringToPath(item.node.title)}/`,
              title: item.node.title,
            })),
        ]}
        defaultValue={
          pathToString(
            pathname.replace("/slot-games", "")
          ).toLocaleLowerCase() || "all slots"
        }
        handleChange={(value) => navigate(value)}
      />
    </div>
  );

  const hashMap = {
    filter: <FilterSection />,
    sort: <SortBy />,
    input: (
      <div className="relative flex flex-col items-start">
        <span className="pb-4 text-[1.7rem] font-bold">Search</span>
        <input
          className="w-full h-[47px] pl-[35px] text-2xl z-100 border-2 rounded-[10px] text-[#000000]"
          type="text"
          placeholder="Search for a game"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <StaticImage
          className="absolute top-[-32px] left-[10px]"
          src="../../../../../images/Search-Outline.png"
          alt="Search_icon"
        />
      </div>
    ),
  };

  return (
    <div className="filter-container mobile relative mb-10 flex gap-0 w-full items-center justify-center flex-col pb-6">
      {component !== defualtView && (
        <div
          className="ml-auto mr-4 mt-4 flex items-center"
          onClick={() => setComponent(defualtView)}
        >
          <StaticImage
            className=""
            src="../../../../../images/cross.png"
            alt="cross_icon"
          />
          Close
        </div>
      )}
      <div className="w-full p-8 pt-2">{hashMap[component]}</div>
      {component === defualtView && (
        <div className={`flex gap-4 w-full items-center flex-raw px-8`}>
          <button
            className={`w-full cursor-pointer h-[46px] text-2xl text-[#421D68] rounded-[30px] bg-[#BBBBBB] flex justify-center items-center`}
            onClick={() =>
              component === "filter"
                ? setComponent("sort")
                : setComponent("filter")
            }
          >
            <StaticImage
              className={`mr-2 ${
                sortValue != "-"
                  ? "has-value"
                  : ""
              }`}
              src="../../../../../images/Union.png"
              alt="Union_icon"
              width={16}
            />
            Sort
          </button>
          <button
            className={`w-full cursor-pointer h-[46px] text-2xl text-[#421D68] rounded-[30px] bg-[#BBBBBB] flex justify-center items-center`}
            onClick={() =>
              component === "input"
                ? setComponent("sort")
                : setComponent("input")
            }
          >
            <StaticImage
              className={`relative mr-2 ${!!searchInput ? "has-value" : ""}`}
              src="../../../../../images/magnifying.png"
              alt="magnifying_icon"
              width={16}
            />
            Search
          </button>
        </div>
      )}
    </div>
  );
};

export default Filters;
