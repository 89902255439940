import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Slots = ({ page, slotsPerPage, filteredSlots, loadMoreCallback, setActiveGameSlot }) => {
  return (
    <div className="inner-container">
      {filteredSlots.length === 0 && <div className="font-bold text-4xl mt-10 text-center w-full">No slot found!</div>}
      <div className="slots-filtered-container grid grid-cols-3  gap-x-2 xl:grid-cols-5 gap-y-5 xl:gap-y-10">
        {filteredSlots.map((item, index) => {
          if (!item.compose__page || !item.compose__page[0]) return;

          return (
            <div
              className="carousel-item hover-glow text-center"
              key={Math.random()}
              aria-hidden="true"
              style={{order: index}}
            >
              <div className="item-links p-8 flex flex-col justify-center items-center space-y-2">
                {(item.gameIframeUrl ||
                  item.longGameIframeUrl?.longGameIframeUrl) && (
                  <button
                    onClick={() => setActiveGameSlot(item)}
                    className="play-demo-button"
                  >
                    Play Demo
                  </button>
                )}
                {item.slotInfoLink && (
                  <Link
                    to={`/${item.compose__page[0].slug}`}
                    className="block text-white text-2xl underline"
                  >
                    Slot Info
                  </Link>
                )}
              </div>
              <GatsbyImage
                className="item-img"
                loading="lazy"
                image={
                  getImage(
                    item.slotIcon.localFile.childImageSharp.gatsbyImageData
                  )!
                }
                alt={item.compose__page[0].title}
              />
              <Link
                to={`/${item.compose__page[0].slug}`}
                className="mobile-click"
              >
                
              </Link>
              <Link className="text-white text-[10px] xl:text-[18px] pl-2 xl:pl-7 slot-title font-bold z-10 relative hover:text-[#9e1dca]" to={`/${item.compose__page[0].slug}`}>
                {item.slotName ?? item.compose__page[0].title.replace("SLOT MACHINE", "")}
              </Link>
            </div>
          );
        })}
      </div>
      {slotsPerPage * page - 1 <= filteredSlots.length && (
        <div className="flex justify-center items-center mt-20">
          <button
            className="purple-button font-bold text-white text-2xl xl:text-4xl text-center"
            onClick={() => loadMoreCallback()}
          >
            <span>LOAD MORE SLOT GAMES</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default Slots;
