import React from "react";
import FiltersMobile from "./Filters.mobile";
import FiltersDesktop from "./Filters.desktop";
import useIsMobile from "../../../../../utilities/useIsMobile";
import "./styles.css";

const Filters = (props) => {
  const isMobile = useIsMobile();
  return isMobile ? (
    <FiltersMobile {...props} />
  ) : (
    <FiltersDesktop {...props} />
  );
};

export default Filters;
