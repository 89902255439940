import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import { TextImageSectionType } from "../../types/sections.type"
import ContentfulRichText from "../../utilities/contentful-rich-text"
import Carousel from "../carousel"

import '../../styles/text-image-section.scss';

const TextImageSection = ({
  containerClass,
  text,
  textBg,
  images,
  imagesSide,
  spaceBetween,
}: TextImageSectionType) => {
  let backgroundColor = textBg
  if (backgroundColor === "bluish") backgroundColor = "#07081d"

  return (
    <div
      className={`text-image-section relative z-10 flex flex-col-reverse items-start justify-between${
        imagesSide === "left"
          ? " xl:flex-row-reverse xl:space-x-reverse"
          : " xl:flex-row"
      }${containerClass ? " " + containerClass : ""}${
        spaceBetween ? " xl:space-x-10 xl:mb-10" : ""
      }`}
    >
      {!!text &&
        <div
          className={`flex flex-col justify-center w-full self-stretch xl:w-1/2 ${
            textBg === "black" || textBg === "bluish" ? " text-white" : ""
          }`}
          style={{ backgroundColor: backgroundColor }}
        >
          <ContentfulRichText
              className='px-10 py-20 xl:py-32 text-image-section'
              raw={text}
            />
        </div>
      }
      <div className='w-full xl:w-1/2 overflow-hidden'>
        <Carousel
          containerClass=''
          edgesFade={false}
          dots={true}
          dotsOnImages={true}
          showArrows={true}
          slidesToScroll={1}
          slidesToShow={1}
          infinite={false}
          data={images.map((image) => {
            return (
              <GatsbyImage
                key={image.id}
                image={getImage(
                  image.localFile.childImageSharp.gatsbyImageData
                )}
                alt={image.title}
              />
            )
          })}
          arrowsElements={{
            left: (
              <StaticImage src='../../images/left-round-arrow.png' alt='' />
            ),
            right: (
              <StaticImage src='../../images/right-round-arrow.png' alt='' />
            ),
          }}
          arrowsPosition={{ left: "0", right: "0" }}
        />
      </div>
    </div>
  )
}

export default TextImageSection
