import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Carousel from "../carousel";
import ContentfulRichText from "../../utilities/contentful-rich-text";
import { SocialProof } from ".";

const SocialProofMobile = (props: SocialProof) => {
  return (
    <div className={`social-proof mobile py-20 xl:py-32 ${props.className}`}>
      <h2 className="font-extrabold text-white uppercase text-center mb-24">
        {props.title}
      </h2>
      <Carousel
        containerClass=""
        edgesFade={false}
        dots={true}
        dotsOnImages={true}
        showArrows={false}
        slidesToScroll={1}
        slidesToShow={1}
        infinite={true}
        centerMode
        lazyLoad="progressive"
        adaptiveHeight
        data={props.items.map(({ authorImage, socialImage, author, date, text }) => {
          return (
            <div className="social-proof-card !flex flex-col relative bg-white p-8 rounded-3xl m-4 items-center">
              <GatsbyImage
                className="social-proof-author-img rounded-full w-[120px] h-[120px]"
                image={getImage(authorImage)!}
                alt=""
              />
              <GatsbyImage
                className="social-proof-img w-[200px] h-[48px] mb-4 mt-[50px]"
                image={getImage(socialImage)!}
                alt=""
                objectFit="contain"
              />
              <ContentfulRichText
                raw={text}
                className='social-proof-text text-[16px] text-center'
              />
              <div className="social-proof-details text-[16px] flex justify-end gap-1 mt-auto pt-8 pb-12 font-bold">
              <div className="social-proof-author">{`${author},`}</div>
                <div className="social-proof-date">{date}</div>
              </div>
            </div>
          );
        })}
      />
    </div>
  );
};

export default SocialProofMobile;
