import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { GallerySectionType } from "../../types/sections.type"
import Carousel from "../carousel"

const GallerySection = ({
  title,
  bgColor,
  slidesToShow,
  data,
  containerClass,
  infinite,
  dots,
  notActiveDown,
  arrowsElements,
  responsive,
  arrowsPosition, // On mobile position is overridden by carousel.css
}: GallerySectionType) => {
  return (
    <div className='px-10 py-20 xl:py-32' style={{ backgroundColor: bgColor }}>
      <h2 className='font-extrabold text-white text-center px-1 xl:pb-16'>
        {title}
      </h2>
      <Carousel
        containerClass={containerClass}
        edgesFade={true}
        dots={dots}
        showArrows={true}
        slidesToScroll={1}
        slidesToShow={slidesToShow ? slidesToShow : 1}
        infinite={infinite}
        notActiveDown={notActiveDown}
        arrowsElements={arrowsElements}
        arrowsPosition={arrowsPosition}
        responsive={responsive}
        lazyLoad={undefined}
        data={data.map((item) => {
          return (
            <div className="gallery-item">
              <div className={`pl-[25px] pr-[25px] xl:p-5 ${item.localFile ? 'block': 'hidden'}`} key={Math.random()}>
                {item.localFile && <GatsbyImage
                  image={getImage(
                    item.localFile.childImageSharp.gatsbyImageData
                  )}
                  alt={item.title}
                />}
              </div>
              <div className={`${!item.localFile ? 'block': 'hidden'}`} key={Math.random()}>{item}</div>
            </div>
          )
        })}
      />
    </div>
  )
}

export default GallerySection
