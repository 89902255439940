import React from "react"

const SlotPageCards = ({ title }) => {
  return (
    <div className='bg-[#07081d] py-14 xl:py-36 px-10 xl:pl-[10%] xl:pr-[10%]'>
      <div className='slot-page-cards-section inner-container'>
        <h2 className='xl:max-w-[80%] xl:m-auto text-center font-extrabold text-white uppercase'>
          HOW TO PLAY {title} SLOTS
        </h2>
        <div className='grid grid-rows-2 grid-cols-2 gap-10 mt-20 xl:flex xl:justify-center xl:space-x-10 xl:gap-0'>
          <div className='slot-info-card-container'>
            <div className='slot-info-card'>
              <div className='step-number'>1</div>
              <div className='step-text'>SET YOUR BET</div>
            </div>
          </div>
          <div className='slot-info-card-container'>
            <div className='slot-info-card'>
              <div className='step-number'>2</div>
              <div className='step-text'>HIT THE SPIN</div>
            </div>
          </div>
          <div className='slot-info-card-container'>
            <div className='slot-info-card'>
              <div className='step-number'>3</div>
              <div className='step-text'>WATCH THE MAGIC HAPPEN</div>
            </div>
          </div>
          <div className='slot-info-card-container'>
            <div className='slot-info-card'>
              <div className='step-number'>4</div>
              <div className='step-text'>CLAIM YOUR PRIZE</div>
            </div>
          </div>
          <div className='slot-info-card-container'>
            <div className='slot-info-card'>
              <div className='step-number'>5</div>
              <div className='step-text'>
                CHOOSE "MAX BET" TO QUALIFY FOR JACKPOT
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SlotPageCards
