import React from "react";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import { SocialProof } from ".";
import ContentfulRichText from "../../utilities/contentful-rich-text";

const SocialProofDesktop = (props: SocialProof) => {
  return (
    <div className={`social-proof py-8 px-[15%] relative ${props.className}`}>
      <StaticImage className="!absolute top-1/3 left-8" src="../../images/#.png" alt="" />
      <StaticImage className="!absolute top-2/4 right-8" src="../../images/like.png" alt="" />

      <h1 className="font-extrabold text-white text-[24px] xl:text-[48px] uppercase text-center mb-8">
        {props.title}
      </h1>
      {props.items.map(({ authorImage, socialImage, author, date, text }) => (
        <div className="social-proof-card flex flex-col relative bg-white p-8 pl-60 mb-8 rounded-3xl">
          <GatsbyImage
            className="social-proof-author-img rounded-full w-[150px] h-[150px]"
            image={getImage(authorImage)!}
            alt=""
          />
          <GatsbyImage
            className="social-proof-img w-[150px] h-[45px] mb-4"
            image={getImage(socialImage)!}
            alt=""
            objectFit="contain"
          />
          <ContentfulRichText
            raw={text}
            className='social-proof-text text-3xl mb-8'
          />
          <div className="social-proof-details text-[14px] flex justify-end gap-1 mt-auto font-bold">
            <div className="social-proof-author">{`${author},`}</div>
            <div className="social-proof-date">{date}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SocialProofDesktop;
