import React, { useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { CustomDropdown } from "../../../../../DS/CustomDropdown";
import {
  stringToPath,
  pathToString,
} from "../../../../../utilities/string-path-convertor";
import { StaticImage } from "gatsby-plugin-image";

const Filters = ({
  slotThemes,
  categories,
  searchInput,
  sortValue,
  setSearchInput,
  setSortType,
  setSortOrder,
}) => {
  const { pathname } = useLocation();
  const [component, setComponent] = useState("sort");

  const FilterSection = () => (
    <>
      <CustomDropdown
        className="slots-filter-select md:mt-0"
        title="Slot Types"
        items={[
          { title: "All Slots", value: "/slot-games/" },
          ...categories
            .filter((category) => category.node.title)
            .map((item) => ({
              value: `/slot-games/${stringToPath(item.node.title)}/`,
              title: item.node.title,
            })),
        ]}
        defaultValue={
          pathToString(
            pathname.replace("/slot-games", "")
          ).toLocaleLowerCase() || "all slots"
        }
        handleChange={(value) => navigate(value)}
      />
      <CustomDropdown
        className="slots-themes-filter-select md:mt-0"
        title="Slot Themes"
        items={[
          { title: "All Slots", value: "/slot-games/" },
          ...slotThemes
            .filter((category) => category.node.title)
            .map((item) => ({
              value: `/slot-games/${stringToPath(item.node.title)}/`,
              title: item.node.title,
            })),
        ]}
        defaultValue={
          pathToString(
            pathname.replace("/slot-games", "")
          ).toLocaleLowerCase() || "all slots"
        }
        handleChange={(value) => navigate(value)}
      />
    </>
  );

  const DesktopView = (
    <>
      <CustomDropdown
        className="sort-by"
        defaultValue={sortValue}
        title="Sort By"
        items={[
          { title: "Most Popular", value: "-"},
          { title: "Title (A-Z)", value: "title-asc" },
          { title: "Title (Z-A)", value: "title-desc" },
          { title: "Old to new", value: "createdAt-asc" },
          { title: "New to old", value: "createdAt-desc" },
        ]}
        handleChange={(value) => {
          const values = value.split("-");
          setSortType(values[0]);
          setSortOrder(values[1]);
        }}
      />
      <FilterSection />
    </>
  );

  return (
    <div className="relative filter-container flex justify-center mt-[50px] mb-12 mx-[10%]">
      {component === "input" && (
        <div
          className="absolute right-8 top-4 ml-auto flex items-center z-50 cursor-pointer"
          onClick={() => setComponent("")}
        >
          <StaticImage
            className=""
            src="../../../../../images/cross.png"
            alt="cross_icon"
          />
          Close
        </div>
      )}
      <div className="relative w-full flex items-end justify-center flex-row gap-10 p-12">
        {component === "input" ? (
          <div className="w-full relative flex flex-col items-start">
            <span className="pb-4 text-[1.7rem] font-bold">Search</span>
            <input
              className="w-full h-[47px] pl-[35px] text-2xl z-100 border-2 rounded-[10px] text-[#000000]"
              type="text"
              placeholder="Search for a game"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <StaticImage
              className="absolute top-[-32px] left-[10px]"
              src="../../../../../images/Search-Outline.png"
              alt="Search_icon"
            />
          </div>
        ) : (
          DesktopView
        )}
        {component !== "input" && (
          <button
            className={`min-w-[200px] cursor-pointer h-[48px] text-2xl text-[#421D68] rounded-[30px] bg-[#BBBBBB] flex justify-center items-center`}
            onClick={() =>
              component === "input"
                ? setComponent("sort")
                : setComponent("input")
            }
          >
            <StaticImage
              className={`relative mr-2 ${!!searchInput ? "has-value" : ""}`}
              src="../../../../../images/magnifying.png"
              alt="magnifying_icon"
              width={16}
            />
            Search
          </button>
        )}
      </div>
    </div>
  );
};

export default Filters;
