import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { CardsSectionType } from "../../types/sections.type"
import InformationCard from "../information-card"
import isTooDark from "../../utilities/is-too-dark"
import ContentfulRichText from "../../utilities/contentful-rich-text"
import { isDesktop } from "react-device-detect"
import AdjustLink from "../adjust-link"

const CardsSection = ({
  title,
  additionalText,
  bottomText,
  iconPlace,
  cards,
  backgroundColor,
  itemsInRow,
  showTitles,
  onDownloadClick,
}: CardsSectionType) => {
  return (
    <div
      className='cards-section px-10 py-20 xl:py-32'
      style={{
        backgroundColor: backgroundColor ? `${backgroundColor}` : "#ffffff",
        color:
          backgroundColor && isTooDark(backgroundColor) ? "#ffffff" : "#000000",
      }}
    >
      <div className='xl:max-w-[75%] m-auto'>
        <h2
          className='font-extrabold text-left'
          style={{
            color:
              backgroundColor && isTooDark(backgroundColor)
                ? "#ffffff"
                : "#000000",
          }}
        >
          {title}
        </h2>
        {additionalText && (
          <ContentfulRichText
            raw={additionalText}
            className='cards-top-text'
          />
        )}
      </div>
      <div
        className={`${
          cards.length % itemsInRow !== 0
            ? "flex-cards-container mt-16"
            : `xl:grid ${
                itemsInRow === 1
                  ? "space-y-0 xl:space-y-6 "
                  : itemsInRow === 2
                  ? " xl:grid-cols-2 space-y-5 xl:space-y-10"
                  : " xl:grid-cols-3 space-y-5 xl:space-y-10"
              } xl:pl-[10%] xl:pr-[10%] mt-10 items-baseline`
        }`}
      >
        {cards.map((card) => {
          return (
            <InformationCard
              itemsInRow={itemsInRow}
              key={Math.random()}
              icon={
                <GatsbyImage
                  className='information-card-icon'
                  image={
                    getImage(
                      card.icon.localFile.childImageSharp.gatsbyImageData
                    )!
                  }
                  alt={title}
                />
              }
              iconPlace={iconPlace}
              backgroundColor={backgroundColor}
              title={card.title}
              showTitle={showTitles}
              text={card.text}
              textColor={card.textColor}
              textAlignment={card.textAlignment}
              readMore={card.readMore && card.readMore.compose__page[0].slug}
              iconSizeOnMobile={card.iconSizeOnMobile}
            />
          )
        })}
      </div>
      {bottomText && (
        <ContentfulRichText
          raw={bottomText}
          className='text-center mt-10 text-[27px]'
        />
      )}
      {bottomText && bottomText.raw.includes("[purple-play-now]") && (
        <div
          className='purple-play-now-container flex justify-center'
          key={Math.random()}
        >
          {!isDesktop ? (
            <AdjustLink className='purple-play-now'>PLAY NOW</AdjustLink>
          ) : (
            <button className='purple-play-now' onClick={onDownloadClick}>
              PLAY NOW
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default CardsSection
