import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";
import "./styles.css";

export const CustomDropdown = ({
  className,
  title,
  items,
  defaultValue,
  handleChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropRef = useRef(null);
  useOnClickOutside(dropRef, () => setIsOpen(false));

  const [value, setValue] = useState(
    items.find((item) => item.title.toLocaleLowerCase() === defaultValue)
      ? defaultValue
      : items[0].title
  );

  useEffect(() => {
    const selectedItem = items.find(
      (item) =>
        item.value.toLocaleLowerCase() === defaultValue?.toLocaleLowerCase()
    );

    selectedItem && setValue(selectedItem.title);
  }, [defaultValue]);

  return (
    <div ref={dropRef} className={`select-wrapper relative ${className}`}>
      <div className="text-[1.7rem] text-left pb-4 font-bold">{title}</div>
      <div
        className="select relative cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="capitalize text-left text-[1.7rem] ">{value}</div>
        <div
          className={`absolute top-[60px] left-0 w-full z-20 bg-white p-2 rounded-lg gap-3 flex flex-col ${
            isOpen ? "block" : "hidden"
          }`}
        >
          {items.map((item) => (
            <div
              key={item.value}
              className={`relative rounded-md text-[1.7rem] text-left text-black hover:bg-[#E7D3FD] px-8 py-2 ${
                value === item.title.toLocaleLowerCase() ? "selected" : ""
              }`}
              onClick={(e) => handleChange(item.value)}
            >
              <a
                href={item.value.toLocaleLowerCase()}
                key={item.id}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleChange(item.value);
                  setIsOpen(false);
                }}
              >
                {item.title}
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className={`select-arrow ${isOpen ? "rotate-180" : ""}`}></div>
    </div>
  );
};
