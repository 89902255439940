import React, { useRef } from "react"
import VideoPlayer from "../video-player"

const YoutubeVideo = ({ youtubeUrl }) => {
  return (
    <div className="youtube-video-section py-32 flex justify-center">
      <VideoPlayer youtube={true} url={youtubeUrl} />
    </div>
  )
}

export default YoutubeVideo
