import { Link } from "gatsby"
import React from "react"
import InformationCardType from "../types/information-card.type"
import ContentfulRichText from "../utilities/contentful-rich-text"
import isTooDark from "../utilities/is-too-dark"

const InformationCard = ({
  icon,
  iconPlace,
  title,
  text,
  backgroundColor,
  readMore,
  textAlignment,
  textColor,
  itemsInRow,
  iconSizeOnMobile,
  showTitle,
}: InformationCardType) => {
  // const textColor =
  //   backgroundColor && isTooDark(backgroundColor) ? "#60647d" : "#9595a7"

  const finalTextColor = textColor
    ? textColor
    : backgroundColor && isTooDark(backgroundColor)
    ? "#60647d"
    : "#000000"

  const titleElement = showTitle && (
    <h3
      className={`font-bold text-[20px] xl:text-[26px] uppercase mb-6 xl:leading-[35px]${
        itemsInRow === 3 ? " xl:min-h-[70px]" : ""
      }`}
      style={{ textAlign: textAlignment }}
    >
      {title}
    </h3>
  )

  const textElement = (
    <ContentfulRichText
      raw={text}
      className='text-[14px] md:text-[20px] mb-6'
      style={{ color: finalTextColor, textAlign: textAlignment }}
    />
  )

  const readMoreElement = readMore && (
    <Link
      className='text-gray-300 text-[14px] xl:text-[20px] underline'
      to={readMore}
    >
      READ MORE
    </Link>
  )

  switch (iconPlace) {
    case "top":
      return (
        <div
          className={`information-card flex flex-col justify-start items-center space-y-8 xl:p-10 xl:mb-24 xl:mb-0${
            iconSizeOnMobile === "Small" ? " card-small-icon-mobile" : ""
          }`}
        >
          {icon}
          {titleElement}
          {textElement}
          {readMoreElement}
        </div>
      )
    case "left":
      return (
        <div
          className={`information-card flex items-start xl:space-x-10 xl:pl-20 xl:pr-20${
            iconSizeOnMobile === "Small" ? " card-small-icon-mobile" : ""
          }`}
        >
          <div>{icon}</div>
          <div>
            <div>{itemsInRow !== 1 && titleElement}</div>
            <div>{textElement}</div>
            <div>{readMoreElement}</div>
          </div>
        </div>
      )
    case "right":
      return (
        <div className='information-card flex justify-evenly items-center space-x-8 xl:p-10 mb-10'>
          <div>
            {titleElement}
            {textElement}
            {readMoreElement}
          </div>
          <div>{icon}</div>
        </div>
      )
    case "bottom":
      return (
        <div className='information-card flex flex-col justify-evenly items-center space-y-8 xl:p-10 mb-10'>
          <div>
            {titleElement}
            {textElement}
            {readMoreElement}
          </div>
          <div>{icon}</div>
        </div>
      )
  }
}

export default InformationCard
