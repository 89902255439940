import React from "react"
import ContentfulRichText from "../../utilities/contentful-rich-text"
import TextImageSection from "./text-image-section"

const TextImageGroupSection = ({
  data,
  spaceBetween,
  description,
  bottomText,
}) => {
  return (
    <div className='py-20 md:py-32 px-10 xl:px-[calc(15vw_-_2.5rem)] text-image-group'>
      <div className='text-left pb-10 top-text'>
        <ContentfulRichText
          raw={description}
          className='text-gray-400 text-[20px]'
        />
      </div>
      {data.map((textImage) => {
        return (
          <TextImageSection
            key={Math.random()}
            images={textImage.gallery.media}
            text={textImage.text}
            textBg={textImage.textBg}
            imagesSide={textImage.imagesSide}
            spaceBetween={spaceBetween}
          />
        )
      })}
      {bottomText && (
        <div className='text-center p-20 bottom-text'>
          <ContentfulRichText
            raw={bottomText}
            className='text-gray-400 text-[20px]'
          />
        </div>
      )}
    </div>
  )
}

export default TextImageGroupSection
