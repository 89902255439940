import React, { useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { GatsbyImageProps } from "gatsby-plugin-image";
import SocialProofMobile from "./SocialProof.mobile";
import SocialProofDesktop from "./SocialProof.desktop";
import "./styles.css";

interface Item {
  text: string;
  date: string;
  author: string;
  socialImage: {
    gatsbyImageData: GatsbyImageProps;
  };
  authorImage: {
    gatsbyImageData: GatsbyImageProps;
  };
}

export interface SocialProof {
  name: string;
  author: string;
  title: string;
  items: Item[];
  className?: string;
}

const SocialProof = ({ data }: { data: SocialProof }) => {
  const [loadComponent, setLoadComponent] = useState(false)
  const [isDesktopView, setIsDesktopView] = useState(false)

  useEffect(() => {
    setLoadComponent(true);
    setIsDesktopView(isDesktop);
  }, [isDesktop]);

  return (
    <div className={loadComponent ? "block" : "hidden"}>
      <SocialProofDesktop
        className={isDesktopView ? "block" : "hidden"}
        {...data}
      />
      <SocialProofMobile
        className={!isDesktopView ? "block" : "hidden"}
        {...data}
      />
    </div>
  );
};

export default SocialProof;
