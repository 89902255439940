import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import SlotInfoTabs from "../slot-info-tabs"
import CardsSection from "./cards-section"
import GallerySection from "./gallery-scetion"
import SimpleText from "./simple-text"
import SlotInfoTable from "./slot-info-table"
import SlotPageCards from "./slot-page-cards"
import SlotsTypes from "./slots-types"
import SlotsWithFilter from "./SlotsWithFilter"
import TextImageGroupSection from "./text-image-group-section"
import TextImageSection from "./text-image-section"
import YoutubeVideo from "./youtube-video"
import SocialProof from "../SocialProof"
import { SlotsWithFilterData } from "../../types/contentful.types"
import Faqs from "../Faqs"
import { isMobile } from "react-device-detect"

const Sections = ({
  createTabs,
  sections,
  seo,
  onDownloadClick,
}: {
  createTabs?: boolean
  sections: any
  seo: any
  onDownloadClick: () => void
}) => {
  let tabsTitles: string[] = []
  let tabsIds: string[] = []

  return (
    <div className='sections'>
      {sections.map((section) => {
        return section.columns.map((column) => {
          switch (column.__typename) {
            case "ContentfulComponentSocialProof": 
              return <SocialProof data={column} />

            case "ContentfulComponentSlotPageCards":
              tabsTitles.push("How To Play")
              tabsIds.push(`section-${column.id}`)
              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <SlotPageCards
                    key={column.id}
                    title={seo.slotInfoCardsTitle}
                  />
                </section>
              )

            case "ContentfulComponentSlotInfoTable":
              if (column.tabTitle) {
                tabsTitles.push(column.tabTitle)
                tabsIds.push(`section-${column.id}`)
              }
              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <SlotInfoTable
                    image={column.image}
                    text={column.text}
                    reels={column.reels}
                    jackpotType={column.jackpotType}
                    jackpotTypeLinkTo={column.jackpotTypeLinkTo}
                    paylines={column.paylines}
                    maxWager={column.maxWager}
                    rtp={column.rtp}
                    bonusRound={column.bonusRound}
                    software={column.software}
                    maxPayout={column.maxPayout}
                    theme={column.theme}
                    themeJson={column.themeJson?.internal.content}
                    softwareJson={column.softwareJson?.internal.content}
                  />
                </section>
              )

            case "ContentfulComponentSlotsTypes":
              return (
                <>
                  <section key={column.id} id={`section-${column.id}`} className={createTabs ? 'slots-page-with-tabs' : ''}>
                    <SlotsTypes
                      slots={column.slots}
                      description={column.description?.description}
                      showDescription={column.showDescription}
                      onDownloadClick={onDownloadClick}
                    />
                  </section>
                  {createTabs && <SlotInfoTabs titles={tabsTitles} ids={tabsIds} />}
                </>
              )

            case "ContentfulComponentGallery":
              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <GallerySection
                    title={column.title}
                    bgColor='#21232f'
                    containerClass='xl:pl-[25%] xl:pr-[25%] pb-40'
                    infinite={true}
                    dots={true}
                    data={column.media}
                    notActiveDown={true}
                    arrowsElements={{
                      left: (
                        <StaticImage
                          src='../../images/left-white-arrow.png'
                          alt='Left Arrow'
                        />
                      ),
                      right: (
                        <StaticImage
                          src='../../images/right-white-arrow.png'
                          alt='Right Arrow'
                        />
                      ),
                    }}
                    arrowsPosition={{ left: "-50%", right: "-50%" }}
                  />
                </section>
              )

            case "ContentfulComponentTextImage":
              if (column.tabTitle) {
                tabsTitles.push(column.tabTitle)
                tabsIds.push(`section-${column.id}`)
              }

              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <TextImageSection
                    images={column.gallery.media}
                    text={column.text}
                    textBg={column.textBg}
                    imagesSide={column.imagesSide}
                  />
                </section>
              )

            case "ContentfulComponentTextImageGroup":
              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <TextImageGroupSection
                    data={column.textImages}
                    bottomText={column.bottomText}
                    spaceBetween={column.spaceBetween}
                    description={column.description}
                  />
                </section>
              )

            case "ContentfulComponentCards":
              tabsTitles.push(column.tabTitle)
              tabsIds.push(`section-${column.id}`)

              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <CardsSection
                    showTitles={column.showTitles}
                    title={column.title}
                    additionalText={column.additionalText}
                    bottomText={column.bottomText}
                    iconPlace={column.iconPlace}
                    itemsInRow={column.itemsInRow}
                    backgroundColor={column.backgroundColor}
                    cards={column.cards}
                    onDownloadClick={onDownloadClick}
                  />
                </section>
              )

            case "ContentfulComponentSlotsWithFilter":
              const {
                allContentfulSlotThemes: { edges: slotThemes },
                allContentfulSlotCategory: { edges: categories },
              }: SlotsWithFilterData = useStaticQuery(graphql`
                query SlotsCategoriesQuery {
                  allContentfulSlotThemes: allContentfulSlotCategory(
                    sort: { order: ASC, fields: title }
                    filter: { slotThemes: { eq: true } }
                  ) {
                    edges {
                      node {
                        title
                        id
                        slotThemes
                      }
                    }
                  }
                  allContentfulSlotCategory(
                    sort: { order: ASC, fields: title }
                    filter: { slotThemes: { ne: true } }
                  ) {
                    edges {
                      node {
                        title
                        id
                        slotThemes
                      }
                    }
                  }
                }
              `);

              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <SlotsWithFilter
                    slots={column.slots.filter(item => item.compose__page && item.compose__page[0])}
                    slotThemes={slotThemes}
                    categories={categories}
                    onDownloadClick={onDownloadClick}
                  />
                </section>
              )

            case "ContentfulComponentSimpleText":
              if (column.tabTitle) {
                tabsTitles.push(column.tabTitle)
                tabsIds.push(`section-${column.id}`)
              }

              return (
                <section key={column.id} id={`section-${column.id}`}>
                  <SimpleText
                    backgroundColor={column.backgroundColor}
                    text={column.text}
                    images={column.images}
                    carouselOptions={{
                      slidesToScroll: 1,
                      slidesToShow: isMobile ? 1 : 2
                    }}
                  />
                </section>
              )

            case "ContentfulFaQsSection": 
              return (
                <Faqs title={column.faqsTilte} faqs={column.faqs} />
              )

            case "ContentfulComponentYoutubeVideo":
              return (
                <section key={column.id} id={`section-${column.id}`}>
                  {column.youtubeUrl && <YoutubeVideo youtubeUrl={column.youtubeUrl.youtubeUrl} />}
                </section>
              )

            default:
              return
          }
        })
      })}
    </div>
  )
}

export default Sections
