import React from "react"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { ContentfulImageType } from "../types/contentful.types"
import VideoPlayer from "./video-player"

const HERO_IMAGE_TYPES: { [key: string]: string } = {
  "video/mp4": "video",
  "image/jpeg": "image",
}

const BackgroundContainer = ({
  children,
  image,
  fader,
  className,
  videoPlaceholder,
}: {
  children: any
  image: ContentfulImageType
  fader?: boolean
  className?: string
  videoPlaceholder?: ContentfulImageType
}) => {
  if (!image) {
    return <>{children}</>;
  }
  const type = HERO_IMAGE_TYPES[image.file.contentType] || "image"
  
  if (type === "video") {
    return (
      <div className="overflow-hidden relative md:pb-24 pt-20 min-h-[450px] md:min-h-[100vh]">
        <div className="video-background-container absolute top-0 opacity-50">
          <div className="w-screen h-screen relative">
            <VideoPlayer
              url={image.file.url}
              poster={videoPlaceholder && videoPlaceholder.localFile.publicURL}
            />
          </div>
        </div>
        <div className="hero-background-fader"></div>
        <div className="video-content relative z-10 pt-20">{children}</div>
      </div>
    )
  } else {
    const bgImage = convertToBgImage(
      getImage(image.localFile.childImageSharp.gatsbyImageData));
    return (
      <BackgroundImage
        Tag="section"
        {...bgImage}
        className={`background-image-container ${className ? ` ${className}`: ''}`}
      >
        {fader && <div className="hero-background-fader z-[-1]"></div>}
        <div className="overflow-hidden relative pt-10">{children}</div>
      </BackgroundImage>
    )
  }
}

export default BackgroundContainer
